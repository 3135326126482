import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Collapse, ListItem } from '@mui/material';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import clsx from 'clsx';
import NavLink from '../../NavLink/NavLink';
import { uiActions } from '../../../redux/actions/ui';
import { MenuWrapper, SubMenuWrapper, StyledButton } from '../styles';

const SidebarMenu = ({ isDrawer }) => {
	const dispatch = useDispatch();
	const [state, setState] = useState();
	// const { userMenu } = useSelector(({ user }) => user, shallowEqual);
	const userMenu = [
		// tmp
		{
			path: 'dashboard',
			caption: 'Dashboard',
			icon: 'home',
		},
		{
			path: 'stats_report',
			caption: 'Reports',
			icon: 'table',
		},
	];

	const toggleMenu = (caption) => {
		setState((state) => ({
			...state,
			[caption]: !state[caption],
		}));
	};

	useEffect(() => {
		const nested =
			userMenu &&
			userMenu.filter((el) => el.sub).map((el) => [el.caption, false]);
		const stateObj = Object.fromEntries(nested);
		setState(stateObj);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{state &&
				_buildMenu({ state, userMenu, toggleMenu, dispatch, isDrawer })}
		</>
	);
};

const _buildMenu = ({
	state,
	userMenu,
	toggleMenu,
	parent,
	dispatch,
	isDrawer,
}) => {
	return (
		<MenuWrapper>
			<SubMenuWrapper>
				{userMenu?.map((el, i) => {
					if (el.sub) {
						return _buildSubMenuItem(
							state,
							el,
							i,
							toggleMenu,
							dispatch,
							isDrawer
						);
					} else {
						return _buildMenuItem({
							el,
							i,
							parent,
							dispatch,
							isDrawer,
							state,
						});
					}
				})}
			</SubMenuWrapper>
		</MenuWrapper>
	);
};

const _buildMenuItem = ({ el, i, parent, dispatch, isDrawer, state }) => {
	let adjustedPath = parent ? `/${parent}/${el.path}` : `/${el.path}`;
	return (
		<ListItem key={i}>
			<StyledButton
				end
				activeClassName='activeNavLink'
				component={NavLink}
				to={adjustedPath}
				onClick={() => isDrawer && dispatch(uiActions.toggleSidebarAction())}
				startIcon={
					el.icon ? (
						<i className={`fa fa-${el.icon}`} />
					) : (
						<i className='fa fa-circle' />
					)
				}
			>
				{el.caption}
			</StyledButton>
		</ListItem>
	);
};

const _buildSubMenuItem = (state, el, i, toggleMenu, dispatch, isDrawer) => {
	return (
		<ListItem key={i} className='Mui-children'>
			<Button
				className={clsx({
					'Mui-active': state[el.caption],
				})}
				startIcon={
					el.icon ? (
						<i className={`fa fa-${el.icon}`} />
					) : (
						<i className='fa fa-circle' />
					)
				}
				endIcon={
					state[el.caption] ? (
						<ExpandLessTwoToneIcon />
					) : (
						<ExpandMoreTwoToneIcon />
					)
				}
				onClick={() => toggleMenu(el.caption)}
			>
				{el.caption}
			</Button>
			<Collapse in={state[el.caption]}>
				{_buildMenu({
					state,
					userMenu: el.sub,
					toggleMenu,
					parent: el.path,
					dispatch,
					isDrawer,
				})}
			</Collapse>
		</ListItem>
	);
};

export default SidebarMenu;
