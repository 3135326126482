import React from 'react';
import { NavLink as NavLinkBase } from 'react-router-dom';

const NavLink = React.forwardRef((props, ref) => (
	<NavLinkBase
		ref={ref}
		to={props.to}
		className={({ isActive }) =>
			`${props.className} ${isActive ? props.activeClassName : ''}`
		}
	>
		{props.children}
	</NavLinkBase>
));

export default NavLink;
