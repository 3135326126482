import { createReducer } from '@reduxjs/toolkit';
import { userActions } from '../actions/user';

const userState = {
	userInfo: {},
	userMenu: [],
};

const dashboardMenu = {
	path: 'dashboard',
	caption: 'Dashboard',
	icon: 'home',
};

const userReducer = createReducer(userState, (builder) => {
	builder
		.addCase(userActions.setUserInfoAction, (state, { payload }) => {
			state.userInfo = payload;
		})
		.addCase(userActions.setUserMenuAction, (state, { payload }) => {
			state.userMenu = [dashboardMenu, ...payload];
		})
		.addDefaultCase((state) => state);
});

export default userReducer;
