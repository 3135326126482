import { baseUrl, endpoints } from './config';

export default class AdvertisersService {
	static async fetchUrl(method, initOptions = {}) {
		const options = {
			...initOptions,
		};
		const response = await fetch(`${baseUrl}${method}`, options);
		return response.json();
	}
	static getTokenObject(xSID) {
		return {
			headers: { 'X-Sid': xSID },
		};
	}
	static handleError(error, methodName) {
		console.log(`${methodName} failed with error: `, error);
	}
	static login = async (data) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
		};
		return this.fetchUrl(endpoints.login, options).catch((error) =>
			this.handleError(error, 'login')
		);
	};

	// GET
	static getTotalSignUps = async ({ xSID, from, to, productID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			`${endpoints.totalSignups}&from_date=${from}&to_date=${to}${
				productID ? '&product_id=' + productID : ''
			}`,
			options
		).catch((error) => this.handleError(error, 'getTotalSignUps'));
	};
	static getTotalFirstDeposits = async ({ xSID, from, to, productID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			`${endpoints.totalFirstDeposits}&from_date=${from}&to_date=${to}${
				productID ? '&product_id=' + productID : ''
			}`,
			options
		).catch((error) => this.handleError(error, 'getTotalFirstDeposits'));
	};
	static getConversionRate = async ({ xSID, from, to, productID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			`${endpoints.conversionRate}?from_date=${from}&to_date=${to}${
				productID ? '&product_id=' + productID : ''
			}`,
			options
		).catch((error) => this.handleError(error, 'getConversionRate'));
	};
	static getConversionRateByProduct = async ({ xSID, from, to }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			`${endpoints.conversionRate}?from_date=${from}&to_date=${to}&by=product`,
			options
		).catch((error) =>
			this.handleError(error, 'getConversionRateByProduct')
		);
	};
	static getFDByOS = async ({ xSID, from, to, productID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			`${endpoints.fdByOS}?from_date=${from}&to_date=${to}&by=os${
				productID ? '&product_id=' + productID : ''
			}`,
			options
		).catch((error) => this.handleError(error, 'getFDByOS'));
	};
	static getProducts = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.products, options).catch((error) =>
			this.handleError(error, 'getProducts')
		);
	};
}
