import { configureStore } from '@reduxjs/toolkit';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from '../saga/rootSaga';
import uiReducer from '../reducers/ui';
import authReducer from '../reducers/auth';
import userReducer from '../reducers/user';
import dashboardReducer from '../reducers/dashboard';

// const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

export default function configureAppStore(preloadedState) {
	const store = configureStore({
		reducer: {
			ui: uiReducer,
			auth: authReducer,
			user: userReducer,
			dashboard: dashboardReducer,
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware()
				.concat(sagaMiddleware),
				// .concat(loggerMiddleware),
		devTools: process.env.NODE_ENV !== 'production',
		preloadedState,
	});

	sagaMiddleware.run(rootSaga);

	return store;
}

// import createSagaMiddleware from '@redux-saga/core';

// const sagaMiddleware = createSagaMiddleware();
// const middleware = [sagaMiddleware];
// const __DEV__ = process.env.NODE_ENV !== 'production';

// if (__DEV__) {
//     const { createLogger } = require('redux-logger');
//     const logger = createLogger();
//     middleware.push(logger);
// }

// export { sagaMiddleware, middleware };
