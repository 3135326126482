import { all } from 'redux-saga/effects';
import { rootMain } from './init';
import { rootAuth } from './auth';
import { rootDashboard } from './dashboard';

export default function* rootSaga() {
	yield all([
		rootMain(),
		rootAuth(),
		rootDashboard(),
	]);
}
