import React, { useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { IconButton, Popover, Tooltip } from '@mui/material';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { uiActions } from '../../../../redux/actions/ui';
import ThemeToggle from './ThemeToggle';

const HeaderSettings = () => {
	const dispatch = useDispatch();
	const ref = useRef(null);
	const { settingsOpen } = useSelector(({ ui }) => ui, shallowEqual);

	const handleOpen = () => {
		dispatch(uiActions.toggleSettingsAction(true));
	};
	const handleClose = () => {
		dispatch(uiActions.toggleSettingsAction(false));
	};

	return (
		<>
			<Tooltip arrow title='Settings'>
				<IconButton color='primary' ref={ref} onClick={handleOpen}>
					<SettingsTwoToneIcon />
				</IconButton>
			</Tooltip>
			<Popover
				anchorEl={ref.current}
				onClose={handleClose}
				open={settingsOpen}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<ThemeToggle />
			</Popover>
		</>
	);
};

export default HeaderSettings;
