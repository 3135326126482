import { createAction } from '@reduxjs/toolkit';

export const dashboardActions = {
	getDashboardPageAction: createAction('dashboard/getDashboardPage'),
	getConversionRateSuccessAction: createAction(
		'dashboard/getConversionRateSuccess'
	),
	getTotalSignUpsSuccessAction: createAction(
		'dashboard/getTotalSignUpsSuccess'
	),
	getTotalFDSuccessAction: createAction('dashboard/getTotalFDSuccess'),
	getLastMonthConvRateSuccessAction: createAction(
		'dashboard/getLastMonthConvRateSuccess'
	),
	getFDByOSSuccessAction: createAction('dashboard/getFDByOSSuccess'),
	getConvRateByProductSuccessAction: createAction(
		'dashboard/getConvRateByProductSuccess'
	),
	getProductsSuccessAction: createAction('dashboard/getProductsSuccess'),
	setErrorAction: createAction('dashboard/setError'),
	setTimeAction: createAction('dashboard/setTime'),
	setProductAction: createAction('dashboard/setProduct'),
	clearStateAction: createAction('dashboard/clearState'),
};
