import { createAction } from '@reduxjs/toolkit';

export const authActions = {
	signinAction: createAction('auth/signin'),
	signinSuccessAction: createAction('auth/signinSuccess'),
	signinFailureAction: createAction('auth/signinFailure'),
	isLoggedInAction: createAction('auth/isLoggedIn'),
	logoutAction: createAction('auth/logout'),
	logoutSuccessAction: createAction('auth/logoutSuccess'),
	notifyReportLibAction: createAction('auth/notifyReportLib'),
};
