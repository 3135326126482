import { createReducer } from '@reduxjs/toolkit';
import { dashboardActions } from '../actions/dashboard';
import { getDate } from '../../utils/date';

const dashboardState = {
	totalSignUps: 0,
	firstDeposits: 0,
	conversionRate: 0,
	lastMonthConversionRate: 0,
	conversionRateByProduct: {},
	firstDepositsByOS: {},
	product: 'All products',
	products: [],
	time: getDate(),
};

const dashboardReducer = createReducer(dashboardState, (builder) => {
	builder
		.addCase(
			dashboardActions.getTotalSignUpsSuccessAction,
			(state, { payload }) => {
				state.totalSignUps = payload;
			}
		)
		.addCase(
			dashboardActions.getTotalFDSuccessAction,
			(state, { payload }) => {
				state.firstDeposits = payload;
			}
		)
		.addCase(
			dashboardActions.getConversionRateSuccessAction,
			(state, { payload }) => {
				state.conversionRate = payload;
			}
		)
		.addCase(
			dashboardActions.getLastMonthConvRateSuccessAction,
			(state, { payload }) => {
				state.lastMonthConversionRate = payload;
			}
		)
		.addCase(
			dashboardActions.getConvRateByProductSuccessAction,
			(state, { payload }) => {
				state.conversionRateByProduct = payload || [];
			}
		)
		.addCase(
			dashboardActions.getFDByOSSuccessAction,
			(state, { payload }) => {
				state.firstDepositsByOS = Object.keys(payload)?.length
					? payload
					: {};
			}
		)
		.addCase(
			dashboardActions.getProductsSuccessAction,
			(state, { payload }) => {
				state.products = payload || [];
			}
		)
		.addCase(dashboardActions.setTimeAction, (state, { payload }) => {
			const date = getDate(payload);
			state.time = date;
		})
		.addCase(dashboardActions.setProductAction, (state, { payload }) => {
			state.product = payload;
		})
		.addCase(dashboardActions.clearStateAction, (state, { payload }) => {
			state.totalSignUps = 0;
			state.firstDeposits = 0;
			state.conversionRate = 0;
			state.lastMonthConversionRate = 0;
			state.conversionRateByProduct = {};
			state.firstDepositsByOS = {};
			state.product = 'All products';
			state.time = getDate();
		})
		.addDefaultCase((state) => state);
});

export default dashboardReducer;
