import { createReducer } from '@reduxjs/toolkit';
import { authActions } from '../actions/auth';

const authState = {
	isAuth: false,
	xSID: null,
	uID: null,
	error: false,
	notifyReport: false,
};

const authReducer = createReducer(authState, (builder) => {
	builder
		.addCase(authActions.signinSuccessAction, (state, { payload }) => {
			state.isAuth = true;
			state.xSID = payload.xSID;
			state.uID = payload.uID;
			state.error = false;
		})
		.addCase(authActions.signinFailureAction, (state, { payload }) => {
			state.isAuth = false;
			state.error = true;
		})
		.addCase(authActions.logoutSuccessAction, (state, { payload }) => {
			state.isAuth = false;
			state.xSID = null;
			state.uID = null;
			state.error = false;
		})
		.addCase(authActions.notifyReportLibAction, (state, { payload }) => {
			state.notifyReport = true;
		})
		.addDefaultCase((state) => state);
});

export default authReducer;
