import { createReducer } from '@reduxjs/toolkit';
import { uiActions } from '../actions/ui';

const uiState = {
	loading: false,
	appTheme: 'GreyGooseTheme',
	sidebarOpen: false,
	settingsOpen: false,
	profileOpen: false,
	alert: {
		show: false,
		severity: 'info',
		body: '',
	},
};

const uiReducer = createReducer(uiState, (builder) => {
	builder
		.addCase(uiActions.startLoaderAction, (state, { payload }) => {
			state.loading = true;
		})
		.addCase(uiActions.endLoaderAction, (state, { payload }) => {
			state.loading = false;
		})
		.addCase(uiActions.toggleSidebarAction, (state, { payload }) => {
			state.sidebarOpen = !state.sidebarOpen;
		})
		.addCase(uiActions.toggleSettingsAction, (state, { payload }) => {
			state.settingsOpen = payload;
		})
		.addCase(uiActions.toggleProfileAction, (state, { payload }) => {
			state.profileOpen = payload;
		})
		.addCase(uiActions.setAlertAction, (state, { payload }) => {
			state.alert = payload;
		})
		.addCase(uiActions.setThemeAction, (state, { payload }) => {
			state.appTheme = payload;
		})
		.addDefaultCase((state) => state);
});

export default uiReducer;
