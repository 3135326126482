import React, { useContext, useRef, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
	Box,
	Button,
	Divider,
	List,
	ListItem,
	ListItemText,
	Popover,
} from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { ThemeContext } from '../../../theme/MuiThemeProvider';
import { authActions } from '../../../redux/actions/auth';
import { uiActions } from '../../../redux/actions/ui';
import {
	UserBoxButton,
	UserBoxText,
	UserBoxLabel,
	UserBoxDescription,
	MenuUserBox,
	ToggleThemeBox,
	AvatarStyled,
} from '../styles';

const HeaderUserbox = ({ dispatch }) => {
	const userInfoDefault = {
		image: 'broken.jpg',
	};
	const { userInfo } = useSelector(({ user }) => user, shallowEqual);
	const { appTheme, profileOpen } = useSelector(({ ui }) => ui, shallowEqual);

	const setThemeName = useContext(ThemeContext);
	const curThemeName = appTheme || 'GreyGooseTheme';
	const ref = useRef(null);
	const [theme, setTheme] = useState(curThemeName);

	const handleOpen = () => {
		dispatch(uiActions.toggleProfileAction(true));
	};
	const handleClose = () => {
		dispatch(uiActions.toggleProfileAction(false));
	};
	const handleLogout = async () => {
		dispatch(authActions.logoutAction());
	};
	const changeTheme = (event) => {
		const themeColor =
			curThemeName === 'GreyGooseTheme'
				? 'NebulaFighterTheme'
				: 'GreyGooseTheme';
		setTheme(themeColor);
		setThemeName(themeColor);
		dispatch(uiActions.toggleProfileAction(false));
	};

	return (
		<>
			<UserBoxButton color='secondary' ref={ref} onClick={handleOpen}>
				<AvatarStyled
					variant='rounded'
					alt={userInfo?.email}
					src={userInfo?.image}
				/>
				<Box sx={{ display: { xl: 'none', md: 'block', xs: 'none' } }}>
					<UserBoxText>
						<UserBoxLabel variant='body1'>
							{userInfo?.firstname} {userInfo?.lastname}
						</UserBoxLabel>
						<UserBoxDescription variant='body2'>
							{userInfo?.email}
						</UserBoxDescription>
					</UserBoxText>
				</Box>
				<Box sx={{ display: { xl: 'none', sm: 'block', xs: 'none' } }}>
					<ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
				</Box>
			</UserBoxButton>
			<Popover
				anchorEl={ref.current}
				onClose={handleClose}
				open={profileOpen}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{ zIndex: 9999 }}
			>
				<MenuUserBox sx={{ minWidth: 210 }} display='flex'>
					<AvatarStyled
						variant='rounded'
						alt={userInfo?.name}
						src={userInfo?.image || userInfoDefault.image}
						sx={{ borderRadius: '6px' }}
					/>
					<UserBoxText>
						<UserBoxLabel variant='body1'>
							{userInfo?.firstname}
							{userInfo?.lastname}
						</UserBoxLabel>
						<UserBoxDescription variant='body2'>
							{userInfo?.email}
						</UserBoxDescription>
					</UserBoxText>
				</MenuUserBox>
				<Divider sx={{ mb: 0, display: { md: 'none', xs: 'block' } }} />
				<List sx={{ p: 1, display: { md: 'none', xs: 'block' } }}>
					<ListItem
						button
						sx={{
							padding: '4px 8px',
							display: { md: 'none', xs: 'block' },
						}}
					>
						<ToggleThemeBox display='flex' onClick={changeTheme}>
							{theme === 'GreyGooseTheme' ? (
								<DarkModeIcon fontSize='small' />
							) : (
								<LightModeIcon fontSize='small' />
							)}
							<ListItemText
								sx={{ marginLeft: '10px' }}
								primary='Toggle theme'
							/>
						</ToggleThemeBox>
					</ListItem>
				</List>
				<Divider />
				<Box sx={{ m: 1 }}>
					<Button color='primary' fullWidth onClick={handleLogout}>
						<LockOpenTwoToneIcon sx={{ mr: 1 }} />
						Sign out
					</Button>
				</Box>
			</Popover>
		</>
	);
};

export default HeaderUserbox;
