import { call, fork, put, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { dashboardActions } from '../actions/dashboard';
import { userActions } from '../actions/user';
import { authActions } from '../actions/auth';
import { uiActions } from '../actions/ui';
import PartnersService from '../../services/partners.service';

function* login({ payload }) {
	try {
		yield put(uiActions.startLoaderAction());
		const response = yield call(PartnersService.login, payload);
		if (!response.error && response.data) {
			const userObj = { xSID: response.data.SID, uID: response.data.info.id };
			const currentTheme = localStorage.getItem('appTheme');
			yield put(authActions.signinSuccessAction(userObj));
			yield put(userActions.setUserMenuAction(response.data.menu));
			yield put(userActions.setUserInfoAction(response.data.info));
			Cookies.set('SID', response.data.SID, {
				expires: new Date(
					new Date().getTime() + (response.data.expires / 60) * 60 * 1000
				),
			});
			localStorage.setItem('uID', response.data.info.id);
			localStorage.setItem('uMenu', JSON.stringify(response.data.menu));
			localStorage.setItem('uInfo', JSON.stringify(response.data.info));
			currentTheme
				? localStorage.setItem('appTheme', currentTheme)
				: localStorage.setItem('appTheme', 'GreyGooseTheme');
			localStorage.setItem(
				'expires',
				Date.now() + Number(response.data.expires) * 1000
			);
		} else {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body: response?.error,
				})
			);
		}
	} catch (error) {
		yield put(authActions.signinFailureAction(error.toString()));
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: error?.toString(),
			})
		);
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}
function* isLoggedIn() {
	try {
		let xSID = Cookies.get('SID');
		let uID = localStorage.getItem('uID');
		let expires = localStorage.getItem('expires');
		let uMenu = localStorage.getItem('uMenu');
		let uInfo = localStorage.getItem('uInfo');
		let appTheme = localStorage.getItem('appTheme');

		if (xSID && Date.now() < expires) {
			const userObj = { xSID, uID };
			yield put(authActions.signinSuccessAction(userObj));
			yield put(userActions.setUserMenuAction(JSON.parse(uMenu)));
			yield put(userActions.setUserInfoAction(JSON.parse(uInfo)));
			yield put(uiActions.setThemeAction(appTheme));
		}
	} catch (error) {
		Cookies.remove('SID');
		localStorage.removeItem('expires');
		localStorage.removeItem('uID');
		localStorage.removeItem('uMenu');
		localStorage.removeItem('uInfo');
		yield put(authActions.signinFailureAction(error));
	}
}
export function* logout() {
	yield put(authActions.notifyReportLibAction());
	yield put(dashboardActions.clearStateAction());
	yield put(uiActions.toggleProfileAction(false));
	Cookies.remove('SID');
	yield localStorage.removeItem('expires');
	yield localStorage.removeItem('uID');
	yield localStorage.removeItem('uMenu');
	yield localStorage.removeItem('uInfo');
	yield put(authActions.logoutSuccessAction());
}
function* watchAuth() {
	yield takeLatest(authActions.signinAction.type, login);
	yield takeLatest(authActions.isLoggedInAction.type, isLoggedIn);
}
function* watchLogout() {
	yield takeLatest(authActions.logoutAction.type, logout);
}
function* rootAuth() {
	yield fork(watchAuth);
	yield fork(watchLogout);
}

export { rootAuth };
