import React from 'react';
import { LogoBox } from './styles';

const Logo = ({ isLogin, cs }) => {
	return (
		<LogoBox className='logo' style={cs}>
			<span className='logoImg'></span>
			{!isLogin && <span className='logoText'>Genius</span>}
		</LogoBox>
	);
};

export default Logo;
