import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import NotRequireAuth from './NotRequireAuth';

const Login = React.lazy(() => import('../screens/Login/Login'));
const Dashboard = React.lazy(() => import('../screens/Dashboard/Dashboard'));
const StatsReport = React.lazy(() =>
	import('../screens/Reports/StatsReport/StatsReport')
);

const routes = [
	{
		path: '*',
		element: (
			<RequireAuth>
				<Dashboard />
			</RequireAuth>
		),
	},
	{
		path: '/',
		element: (
			<RequireAuth>
				<Dashboard />
			</RequireAuth>
		),
	},
	{
		path: '/dashboard',
		element: (
			<RequireAuth>
				<Dashboard />
			</RequireAuth>
		),
	},
	{
		path: '/stats_report',
		element: (
			<RequireAuth>
				<StatsReport />
			</RequireAuth>
		),
	},
	{
		path: '/login',
		element: (
			<NotRequireAuth>
				<Login />
			</NotRequireAuth>
		),
	},
];

const routesBuilder = (_routes) => {
	return _routes?.map((route, i) => {
		return <Route key={i} path={route.path} element={route.element} />;
	});
};
export { routes, routesBuilder };
