import { Box } from '@mui/material';
import HeaderSettings from './HeaderSettings';

const HeaderButtons = () => {
	return (
		<Box sx={{ mr: 1, display: { lg: 'block', md: 'block', xs: 'none' } }}>
			<HeaderSettings />
		</Box>
	);
};

export default HeaderButtons;
