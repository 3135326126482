import moment from 'moment';

const getToday = () => {
	return {
		from: moment().startOf('day').format('YYYY-MM-DD'), // 'YYYY-MM-DD HH:mm:ss'
		// to: moment()
		// 	.startOf('day')
		// 	.subtract(-1, 'd')
		// 	.format('YYYY-MM-DD'),
		to: moment().startOf('day').format('YYYY-MM-DD'),
	};
};
const getYesterday = () => {
	return {
		from: moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD'),
		to: moment().subtract(1, 'd').format('YYYY-MM-DD'),
	};
};
const getThisWeek = () => {
	return {
		from: moment().startOf('week').format('YYYY-MM-DD'),
		to: moment().subtract(1, 'd').format('YYYY-MM-DD'),
	};
};
const getThisMonth = () => {
	return {
		from: moment().startOf('month').format('YYYY-MM-DD'),
		to: moment().subtract(1, 'd').format('YYYY-MM-DD'),
	};
};
const getThisYear = () => {
	return {
		from: moment().startOf('year').format('YYYY-MM-DD'),
		to: moment().subtract(1, 'd').format('YYYY-MM-DD'),
	};
};
export const getLastMonth = () => {
	return {
		from: moment()
			.startOf('month')
			.subtract(1, 'month')
			.format('YYYY-MM-DD'),
		to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
	};
};
// const getLastWeek = () => {
// 	return {
// 		from: moment().subtract(7, 'd').startOf('day').format('YYYY-MM-DD'),
// 		to: moment().subtract(1, 'd').format('YYYY-MM-DD'),
// 	};
// };
// const getLastThreeWeeks = () => {
// 	return {
// 		from: moment().subtract(21, 'd').startOf('day').format('YYYY-MM-DD'),
// 		to: moment().subtract(1, 'd').format('YYYY-MM-DD'),
// 	};
// };

export const getDate = (date) => {
	switch (date) {
		case 'Today':
			return getToday();
		case 'Yesterday':
			return getYesterday();
		case 'This week':
			return getThisWeek();
		case 'This month':
			return getThisMonth();
		case 'Last month':
			return getLastMonth();
		case 'This year':
			return getThisYear();
		// case 'Last 7 Days':
		// 	return getLastWeek();
		// case 'Last 3 Weeks':
		// 	return getLastThreeWeeks();
		default:
			return getYesterday();
	}
};
