import { styled, Box } from '@mui/material';
import logo from '../../theme/assets/logo.3b726687.png';

export const LogoBox = styled(Box)(
	({ theme }) => `
    display: inline-flex;
    align-items: center;
    height: 48px;
    box-sizing: border-box !important;

    .logoImg {
        display: inline-flex;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        background: url(${logo}) no-repeat;
        background-size: contain;
    }
    .logoText {
        font-size: 20px;
        font-weight: bold;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  `
);
