import React from 'react';
import { Routes } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import Loader from '../components/Loader/Loader';
import { PrivateContent, PrivateWrapper } from './styles';
import { routes, routesBuilder } from './routes';

const Private = () => {
	return (
		<>
			<Sidebar />
			<PrivateWrapper>
				<Header />
				<PrivateContent>
					<React.Suspense fallback={<Loader />}>
						<Routes>{routesBuilder(routes)}</Routes>
					</React.Suspense>
				</PrivateContent>
			</PrivateWrapper>
		</>
	);
};

export default Private;
