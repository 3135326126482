import { createAction } from '@reduxjs/toolkit';

export const uiActions = {
	setAlertAction: createAction('ui/setAlert'),
	startLoaderAction: createAction('ui/startLoader'),
	endLoaderAction: createAction('ui/endLoader'),
	setThemeAction: createAction('ui/setTheme'),
	toggleSidebarAction: createAction('ui/toggleSidebar'),
	toggleSettingsAction: createAction('ui/toggleSettings'),
	toggleProfileAction: createAction('ui/toggleProfile'),
};
