import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { initActions } from './redux/actions/init';
import Private from './navigator/Private';
import Public from './navigator/Public';
import MuiThemeProvider from './theme/MuiThemeProvider';

const App = () => {
	const dispatch = useDispatch();
	const { isAuth } = useSelector(({ auth }) => auth, shallowEqual);

	useEffect(() => {
		const initilize = () => {
			dispatch(initActions.initializeAction());
		};

		initilize();
	}, [dispatch]);

	return <MuiThemeProvider>{_buildContent(isAuth)}</MuiThemeProvider>;
};

const _buildContent = (isAuth) => {
	return isAuth ? <Private /> : <Public />;
};

export default App;
