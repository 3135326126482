import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, Drawer } from '@mui/material';
import SidebarMenu from './SidebarMenu';
import Logo from '../Logo/Logo';
import { uiActions } from '../../redux/actions/ui';
import { SidebarWrapper, TopSection, SidebarDivider } from './styles';

const Sidebar = () => {
	const { sidebarOpen } = useSelector(({ ui }) => ui, shallowEqual);
	const dispatch = useDispatch();
	const toggleSidebar = () => {
		dispatch(uiActions.toggleSidebarAction());
	};

	return (
		<>
			<Box sx={{ display: { lg: 'block' } }}>
				<SidebarWrapper>
					<Scrollbars autoHide>
						<TopSection>
							<Logo />
						</TopSection>
						<SidebarDivider sx={{ my: 2, mx: 2 }} />
						<SidebarMenu />
					</Scrollbars>
				</SidebarWrapper>
			</Box>
			<Box sx={{ display: { lg: 'none' } }}>
				<Drawer
					anchor='left'
					open={sidebarOpen}
					onClose={toggleSidebar}
					variant='temporary'
					elevation={9}
					sx={{zIndex: 1302}}
				>
					<SidebarWrapper>
						<Scrollbars autoHide>
							<TopSection>
								<Logo />
							</TopSection>
							<SidebarDivider sx={{ my: 2, mx: 2 }} />
							<SidebarMenu isDrawer />
						</Scrollbars>
					</SidebarWrapper>
				</Drawer>
			</Box>
		</>
	);
};

export default Sidebar;
