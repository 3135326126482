export const host = 'partners.eshkol.io';
export const baseUrl = `https://${host}/api`;
export const endpoints = {
    login: '/login',
    totalSignups: '/dashboard/totals?actiontype=su',
    totalFirstDeposits: '/dashboard/totals?actiontype=fd',
    conversionRate: '/dashboard/conversion_rate',
    fdByOS: '/dashboard/fd',
    products: '/product',
};
