import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { uiActions } from '../actions/ui';
import { dashboardActions } from '../actions/dashboard';
import PartnersService from '../../services/partners.service';
import { logout } from './auth';
import { getLastMonth } from '../../utils/date';

function* getDashboardPage({ payload }) {
	const { from, to } = yield getLastMonth();
	const { products } = yield select(({ dashboard }) => dashboard);

	yield put(uiActions.startLoaderAction());
	yield !products?.length &&
		call(
			handleResponse,
			PartnersService.getProducts,
			payload,
			dashboardActions.getProductsSuccessAction
		);
	yield all([
		call(
			handleResponse,
			PartnersService.getTotalSignUps,
			payload,
			dashboardActions.getTotalSignUpsSuccessAction
		),
		call(
			handleResponse,
			PartnersService.getTotalFirstDeposits,
			payload,
			dashboardActions.getTotalFDSuccessAction
		),
		call(
			handleResponse,
			PartnersService.getConversionRate,
			payload,
			dashboardActions.getConversionRateSuccessAction
		),
		call(
			handleResponse,
			PartnersService.getConversionRate,
			{ ...payload, from, to },
			dashboardActions.getLastMonthConvRateSuccessAction
		),
		call(
			handleResponse,
			PartnersService.getConversionRateByProduct,
			payload,
			dashboardActions.getConvRateByProductSuccessAction
		),
		call(
			handleResponse,
			PartnersService.getFDByOS,
			payload,
			dashboardActions.getFDByOSSuccessAction
		),
	]);
	yield put(uiActions.endLoaderAction());
}
function* handleResponse(fetchFunc, payload, successHandler, alert) {
	try {
		const response = yield call(fetchFunc, payload);
		if (!!response?.error) {
			yield put(dashboardActions.setErrorAction());
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body:
						typeof response?.error === 'string'
							? response.error
							: response.error.join(', '),
				})
			);
		} else {
			yield put(successHandler(response.data));
			yield alert &&
				put(
					uiActions.setAlertAction({
						show: true,
						severity: 'success',
						body: alert,
					})
				);
		}
		yield response?.error?.includes('Please Log in') && logout();
	} catch (error) {
		yield put(dashboardActions.setErrorAction());
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: typeof error === 'string' ? error : error.join(', '),
			})
		);
	}
}

function* watchDashboard() {
	yield takeLatest(
		dashboardActions.getDashboardPageAction.type,
		getDashboardPage
	);
}

function* rootDashboard() {
	yield fork(watchDashboard);
}

export { rootDashboard };
